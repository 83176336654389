import { Button, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-white.svg';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useUserRoles } from '../../../queries/useUsers';
import { useNavigate } from 'react-router';
import { useAtomValue, useSetAtom } from 'jotai';
import { impersonateRoleState, userState } from '../../../state/UIState';
import { User, USER_ROLE } from '../../../types';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UsersHeader = () => {
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: roles } = useUserRoles();
  const navigate = useNavigate();
  const user = useAtomValue(userState) as User;
  const setImpersonateRole = useSetAtom(impersonateRoleState);
  const roleOptions = useMemo(() => {
    return roles?.map((r) => ({ name: r.name })).filter((r) => r.name !== user?.role.name) ?? [];
  }, [roles, user?.role.name]);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSelectRole = (role: { name: string }) => {
    localStorage.setItem('impersonate', role.name);
    setImpersonateRole(role.name as USER_ROLE);
    navigate('/');
    handleCloseMenu();
  };

  return (
    <MainSection>
      <TitleWrapper>
        <Typography variant='h3' color={colors.primary[90]}>
          User Management
        </Typography>
        <Typography variant='body' color={colors.primary[70]}>
          Organize and secure your team
        </Typography>
      </TitleWrapper>
      <ControllsWrapper>
        <Button
          onClick={handleClick}
          startIcon={<UserIcon />}
          variant='contained'
          style={{ width: '179px' }}
        >
          Impersonate
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            handleCloseMenu();
          }}
          PaperProps={{ style: { width: '180px' } }}
        >
          {roleOptions.map((c) => (
            <MenuItem
              key={c.name}
              onClick={(e) => {
                e.stopPropagation();
                onSelectRole(c);
              }}
            >
              <Typography variant='body' color={colors.primary[90]} style={{ marginLeft: '4px' }}>
                {c.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </ControllsWrapper>
    </MainSection>
  );
};
